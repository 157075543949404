<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Identitas perusahaan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Identitas perusahaan</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card bg-white pt-4">
          <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="grid">
              <div class="col-12 md:col-12">
                <div class="formgrid grid">
                  <div class="field col-2">
                    <label
                      :class="{ 'p-error': v$.form.code.$invalid && submitted }"
                      >Kode *
                    </label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="v$.form.code.$model"
                      type="text"
                      class="w-full"
                      maxlength="10"
                      :class="{
                        'p-invalid': v$.form.code.$invalid && submitted,
                      }"
                    />
                    <small
                      v-if="
                        (v$.form.code.$invalid && submitted) ||
                        v$.form.code.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.code.required.$message }}</small
                    >
                  </div>
                  <div class="field col-6">
                    <label
                      :class="{ 'p-error': v$.form.name.$invalid && submitted }"
                      >Nama perusahaan *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="v$.form.name.$model"
                      type="text"
                      :class="{
                        'p-invalid': v$.form.name.$invalid && submitted,
                      }"
                      maxlength="150"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.name.$invalid && submitted) ||
                        v$.form.name.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.name.required.$message }}</small
                    >
                  </div>
                  <div class="field col-4">
                    <label>Telepon </label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.phone"
                      type="text"
                      maxlength="150"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12">
                    <label
                      :class="{
                        'p-error': v$.form.address.$invalid && submitted,
                      }"
                      >Alamat *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <Textarea
                      v-else
                      v-model="v$.form.address.$model"
                      :class="{
                        'p-invalid': v$.form.address.$invalid && submitted,
                      }"
                      maxlength="150"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.address.$invalid && submitted) ||
                        v$.form.address.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.address.required.$message }}</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-content-end">
              <Skeleton v-if="isLoading" width="110px" height="35px" />
              <Button
                v-else
                :loading="isLoadingSave"
                :disabled="disableSave"
                type="submit"
                label="Simpan"
                icon="pi pi-check"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import ProfileService from '@/services/ProfileService'
import errorHandler from '@/helpers/error-handler'

export default {
  name: 'IdentitasPerusahaan',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      profileService: null,
      form: {
        name: '',
        address: '',
        city: '',
        phone: '',
      },

      dialog: false,
      dialogHapus: false,
      submitted: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      disableSave: false,
    }
  },
  created() {
    this.profileService = new ProfileService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.profileService
        .getCompanyProfile()
        .then((res) => {
          if (res.data.status === 1) {
            this.form = res.data.data
            this.disableSave = false
          }
        })
        .catch((err) => {
          this.disableSave = true
          errorHandler(err, 'Identitas perusahaan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      const form = this.form
      delete form.id

      this.isLoadingSave = true
      this.profileService
        .updateCompanyProfile(this.form)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Identitas perusahaan',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Identitas perusahaan', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
  },
  validations() {
    return {
      form: {
        code: {
          required: helpers.withMessage(
            'Kode perusahaan harus diisi.',
            required
          ),
        },
        name: {
          required: helpers.withMessage(
            'Nama perusahaan harus diisi.',
            required
          ),
        },
        address: {
          required: helpers.withMessage('Alamat harus diisi.', required),
        },
      },
    }
  },
}
</script>
